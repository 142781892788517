import { experienceProgramData } from 'src/assets/database/webData';
import { BOARD_SET_WIDTH_WEB } from 'src/consts/user-web.const';
import { IExperienceProgram } from 'src/interfaces/user-web.interface';
import { FlexBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

interface IProps {
  currentId: number;
}

export default function WebProgramExperienceDetail({ currentId }: IProps) {
  const currentIndex = currentId - 1;
  return (
    <BoardExperienceDetail>
      <Banner color={experienceProgramData[currentIndex]?.color}>
        <BannerSetWidth>
          <BannerTextBox>
            <BannerTitle>
              {experienceProgramData[currentIndex]?.categoryName}
            </BannerTitle>
            <BannerSummary>
              {experienceProgramData[currentIndex]?.summary}
            </BannerSummary>
          </BannerTextBox>
          <BannerImage src={experienceProgramData[currentIndex]?.image} />
        </BannerSetWidth>
      </Banner>
      <Main>
        <DetailListBoard>
          {experienceProgramData[currentIndex]?.programList?.map(
            (item: IExperienceProgram) => (
              <DetailElement>
                <DetailDate>{item?.date}</DetailDate>
                <DetailName>{item?.name}</DetailName>
                <DetailContentBox>
                  <DetailExplanation>
                    {item?.explanation
                      .split(/(\*\*.*?\*\*)/g)
                      .map((part, index) => {
                        if (part.startsWith('**') && part.endsWith('**')) {
                          return <b key={index}>{part.slice(2, -2)}</b>;
                        }
                        return part;
                      })}
                  </DetailExplanation>
                  <DetailExtraInfoBox>
                    <DetailExtraInfo>
                      <h1>대상</h1>
                      <VerticalLine />
                      <h2>{item?.subject}</h2>
                    </DetailExtraInfo>
                    <DetailExtraInfo>
                      <h1>장소</h1>
                      <VerticalLine />
                      <h2>{item?.place}</h2>
                    </DetailExtraInfo>
                  </DetailExtraInfoBox>
                </DetailContentBox>
                <DetailButton>사전 접수</DetailButton>
              </DetailElement>
            ),
          )}
        </DetailListBoard>
      </Main>
    </BoardExperienceDetail>
  );
}

const BoardExperienceDetail = styled(FlexBox)`
  width: 100dvw;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Banner = styled(FlexBox)<{ color: string }>`
  width: 100dvw;
  height: 240px;
  background-color: ${props => props.color};
`;
const BannerSetWidth = styled(FlexBox)`
  width: ${BOARD_SET_WIDTH_WEB}px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const BannerTextBox = styled(FlexBox)`
  margin-top: 48px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const BannerTitle = styled.h1`
  color: #fff;
  font-family: Cafe24Ssurround;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
`;
const BannerSummary = styled.h2`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
`;
const BannerImage = styled.img`
  width: 280px;
  height: 168px;

  margin-top: 40px;
  margin-bottom: 36px;
`;

const Main = styled(FlexBox)`
  width: 100dvw;
  background-color: #fff;
`;

const DetailListBoard = styled(FlexBox)`
  width: ${BOARD_SET_WIDTH_WEB}px;
  margin: 0 auto;
  padding-top: 68px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const DetailElement = styled(FlexBox)`
  padding: 24px 0 48px 0;

  border-bottom: 1px solid #f0f2f9;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  &:last-child {
    border-bottom: none;
  }
`;

const DetailDate = styled.p`
  width: 200px;

  margin-right: 24px;

  color: #101010;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 21px */

  white-space: pre-line;
`;

const DetailName = styled.p`
  width: 180px;

  margin-right: 24px;

  color: #101010;
  font-family: Cafe24Ssurround;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */

  white-space: pre-line;
`;

const DetailContentBox = styled(FlexBox)`
  width: 600px;

  margin-right: 72px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const DetailExplanation = styled.p`
  width: 100%;

  margin-bottom: 24px;

  color: #101010;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  white-space: pre-line;
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 12px;
  background-color: #505050;
`;
const DetailExtraInfoBox = styled(FlexBox)`
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`;

const DetailExtraInfo = styled(FlexBox)`
  justify-content: flex-start;
  align-items: center;
  gap: 6px;

  h1,
  h2 {
    color: #505050;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    white-space: pre-line;
  }
  h2 {
    font-weight: 600;
  }
`;

const DetailButton = styled.button`
  width: 100px;
  padding: 12px 12px;

  border-radius: 8px;
  background: #f84851;

  color: #fff;
  text-align: center;
  font-family: 'Pretendard Variable';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 21px */
  letter-spacing: -0.225px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
