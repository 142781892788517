import { IMG_BUCKET_PC } from 'src/consts/user-web.const';

export const MAIN_BACKGROUND_1 = `${IMG_BUCKET_PC}MainBackground1.webp`;
export const MAIN_BACKGROUND_1_TEXT_IMAGE = `${IMG_BUCKET_PC}MainPageLogo.webp`;
export const MAIN_CONTENT = `${IMG_BUCKET_PC}MainContent.webp`;
export const MAIN_BACKGROUND_2 = `${IMG_BUCKET_PC}MainBackground2.webp`;
export const MAIN_CONTENT_TIME_TABLE = `${IMG_BUCKET_PC}MainContentTimeTable.webp`;

export const PROGRAM_MAIN_BANNER = `${IMG_BUCKET_PC}MainProgramBanner.webp`;
export const PROGRAM_MAIN_DAY1 = `${IMG_BUCKET_PC}MainProgramDay1.webp`;
export const PROGRAM_MAIN_DAY2 = `${IMG_BUCKET_PC}MainProgramDay2.webp`;
export const PROGRAM_MAIN_DAY3 = `${IMG_BUCKET_PC}MainProgramDay3.webp`;
export const PROGRAM_CONTENT = `${IMG_BUCKET_PC}MainProgramContent.webp`;

export const experienceProgramData = [
  {
    id: 1,
    categoryName: '경연대회',
    summary: '경연대회 관련 멘트 한줄을 넣을까',
    image: '',
    color: '#8383B3',
    programList: [
      {
        id: 1,
        date: '3월 14일(금) 오후 1시',
        name: '달집만들기\n경연대회',
        explanation:
          '**제주들블축제를 상징하는 대형 달집을 직접 만들고, 상품도 받아가세요!** 정월 대보름을 기념해 10단 높이의 대형 달집을 만들고 액막이와 풍년을 기원해 보세요. 우수팀에게는 상품이 주어지며, 참가만 해도 기념품이 제공됩니다.',
        subject: '사전 접수 및 현장 접수 10팀',
        place: '새별오름 광장(불의 광장)',
      },
      {
        id: 2,
        date: '3월 15일(토) 오후 1시\n결승 오후 5시',
        name: '제주 넉둥베기\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회! 참가자 480명이 8개 팀으로 나누다.',
        subject: '사전접수 8팀, 읍면동 대회 상위 9팀',
        place: '새별오름 광장, 주무대(결승전)',
      },
      {
        id: 3,
        date: '3월 16일(일) 오전 11시',
        name: '제주 민속놀이\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회!',
        subject: '사전 접수자 480명',
        place: '새별오름 광장, 새별오름 일원',
      },
    ],
  },
  {
    id: 2,
    categoryName: '민속놀이 체험 읍면동 대항',
    summary: '경연대회 관련 멘트 한줄을 넣을까',
    image: '',
    color: '#D3ACA7',
    programList: [
      {
        id: 1,
        date: '3월 14일(금) 오후 1시',
        name: '달집만들기\n경연대회',
        explanation:
          '**제주들블축제를 상징하는 대형 달집을 직접 만들고, 상품도 받아가세요!** 정월 대보름을 기념해 10단 높이의 대형 달집을 만들고 액막이와 풍년을 기원해 보세요. 우수팀에게는 상품이 주어지며, 참가만 해도 기념품이 제공됩니다.',
        subject: '사전 접수 및 현장 접수 10팀',
        place: '새별오름 광장(불의 광장)',
      },
      {
        id: 2,
        date: '3월 15일(토) 오후 1시\n결승 오후 5시',
        name: '제주 넉둥베기\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회! 참가자 480명이 8개 팀으로 나누다.',
        subject: '사전접수 8팀, 읍면동 대회 상위 9팀',
        place: '새별오름 광장, 주무대(결승전)',
      },
      {
        id: 3,
        date: '3월 16일(일) 오전 11시',
        name: '제주 민속놀이\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회!',
        subject: '사전 접수자 480명',
        place: '새별오름 광장, 새별오름 일원',
      },
    ],
  },
  {
    id: 3,
    categoryName: '제주 전통체험',
    summary: '경연대회 관련 멘트 한줄을 넣을까',
    image: '',
    color: '#D3CCA7',
    programList: [
      {
        id: 1,
        date: '3월 14일(금) 오후 1시',
        name: '달집만들기\n경연대회',
        explanation:
          '**제주들블축제를 상징하는 대형 달집을 직접 만들고, 상품도 받아가세요!** 정월 대보름을 기념해 10단 높이의 대형 달집을 만들고 액막이와 풍년을 기원해 보세요. 우수팀에게는 상품이 주어지며, 참가만 해도 기념품이 제공됩니다.',
        subject: '사전 접수 및 현장 접수 10팀',
        place: '새별오름 광장(불의 광장)',
      },
      {
        id: 2,
        date: '3월 15일(토) 오후 1시\n결승 오후 5시',
        name: '제주 넉둥베기\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회! 참가자 480명이 8개 팀으로 나누다.',
        subject: '사전접수 8팀, 읍면동 대회 상위 9팀',
        place: '새별오름 광장, 주무대(결승전)',
      },
      {
        id: 3,
        date: '3월 16일(일) 오전 11시',
        name: '제주 민속놀이\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회!',
        subject: '사전 접수자 480명',
        place: '새별오름 광장, 새별오름 일원',
      },
    ],
  },
  {
    id: 4,
    categoryName: '불턱 체험',
    summary: '경연대회 관련 멘트 한줄을 넣을까',
    image: '',
    color: '#A2CAA0',
    programList: [
      {
        id: 1,
        date: '3월 14일(금) 오후 1시',
        name: '달집만들기\n경연대회',
        explanation:
          '**제주들블축제를 상징하는 대형 달집을 직접 만들고, 상품도 받아가세요!** 정월 대보름을 기념해 10단 높이의 대형 달집을 만들고 액막이와 풍년을 기원해 보세요. 우수팀에게는 상품이 주어지며, 참가만 해도 기념품이 제공됩니다.',
        subject: '사전 접수 및 현장 접수 10팀',
        place: '새별오름 광장(불의 광장)',
      },
      {
        id: 2,
        date: '3월 15일(토) 오후 1시\n결승 오후 5시',
        name: '제주 넉둥베기\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회! 참가자 480명이 8개 팀으로 나누다.',
        subject: '사전접수 8팀, 읍면동 대회 상위 9팀',
        place: '새별오름 광장, 주무대(결승전)',
      },
      {
        id: 3,
        date: '3월 16일(일) 오전 11시',
        name: '제주 민속놀이\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회!',
        subject: '사전 접수자 480명',
        place: '새별오름 광장, 새별오름 일원',
      },
    ],
  },
  {
    id: 5,
    categoryName: '공예 체험',
    summary: '경연대회 관련 멘트 한줄을 넣을까',
    image: '',
    color: '#99C8C9',
    programList: [
      {
        id: 1,
        date: '3월 14일(금) 오후 1시',
        name: '달집만들기\n경연대회',
        explanation:
          '**제주들블축제를 상징하는 대형 달집을 직접 만들고, 상품도 받아가세요!** 정월 대보름을 기념해 10단 높이의 대형 달집을 만들고 액막이와 풍년을 기원해 보세요. 우수팀에게는 상품이 주어지며, 참가만 해도 기념품이 제공됩니다.',
        subject: '사전 접수 및 현장 접수 10팀',
        place: '새별오름 광장(불의 광장)',
      },
      {
        id: 2,
        date: '3월 15일(토) 오후 1시\n결승 오후 5시',
        name: '제주 넉둥베기\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회! 참가자 480명이 8개 팀으로 나누다.',
        subject: '사전접수 8팀, 읍면동 대회 상위 9팀',
        place: '새별오름 광장, 주무대(결승전)',
      },
      {
        id: 3,
        date: '3월 16일(일) 오전 11시',
        name: '제주 민속놀이\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회!',
        subject: '사전 접수자 480명',
        place: '새별오름 광장, 새별오름 일원',
      },
    ],
  },
  {
    id: 6,
    categoryName: '오름 체험',
    summary: '경연대회 관련 멘트 한줄을 넣을까',
    image: '',
    color: '#98A9C9',
    programList: [
      {
        id: 1,
        date: '3월 14일(금) 오후 1시',
        name: '달집만들기\n경연대회',
        explanation:
          '**제주들블축제를 상징하는 대형 달집을 직접 만들고, 상품도 받아가세요!** 정월 대보름을 기념해 10단 높이의 대형 달집을 만들고 액막이와 풍년을 기원해 보세요. 우수팀에게는 상품이 주어지며, 참가만 해도 기념품이 제공됩니다.',
        subject: '사전 접수 및 현장 접수 10팀',
        place: '새별오름 광장(불의 광장)',
      },
      {
        id: 2,
        date: '3월 15일(토) 오후 1시\n결승 오후 5시',
        name: '제주 넉둥베기\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회! 참가자 480명이 8개 팀으로 나누다.',
        subject: '사전접수 8팀, 읍면동 대회 상위 9팀',
        place: '새별오름 광장, 주무대(결승전)',
      },
      {
        id: 3,
        date: '3월 16일(일) 오전 11시',
        name: '제주 민속놀이\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회!',
        subject: '사전 접수자 480명',
        place: '새별오름 광장, 새별오름 일원',
      },
    ],
  },
  {
    id: 7,
    categoryName: '참여형 체험',
    summary: '경연대회 관련 멘트 한줄을 넣을까',
    image: '',
    color: '#86868E',
    programList: [
      {
        id: 1,
        date: '3월 14일(금) 오후 1시',
        name: '달집만들기\n경연대회',
        explanation:
          '**제주들블축제를 상징하는 대형 달집을 직접 만들고, 상품도 받아가세요!** 정월 대보름을 기념해 10단 높이의 대형 달집을 만들고 액막이와 풍년을 기원해 보세요. 우수팀에게는 상품이 주어지며, 참가만 해도 기념품이 제공됩니다.',
        subject: '사전 접수 및 현장 접수 10팀',
        place: '새별오름 광장(불의 광장)',
      },
      {
        id: 2,
        date: '3월 15일(토) 오후 1시\n결승 오후 5시',
        name: '제주 넉둥베기\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회! 참가자 480명이 8개 팀으로 나누다.',
        subject: '사전접수 8팀, 읍면동 대회 상위 9팀',
        place: '새별오름 광장, 주무대(결승전)',
      },
      {
        id: 3,
        date: '3월 16일(일) 오전 11시',
        name: '제주 민속놀이\n전국대회',
        explanation:
          '**제주 줄다리기, 집줄놓기, 듬돌들기**와 같은 7개의 제주 전통놀이를 경쟁 형식으로 즐길 수 있는 기회!',
        subject: '사전 접수자 480명',
        place: '새별오름 광장, 새별오름 일원',
      },
    ],
  },
];
