// import home from 'src/assets/images/web/HomeImage.webp';
import {
  MAIN_BACKGROUND_1,
  MAIN_BACKGROUND_1_TEXT_IMAGE,
  MAIN_BACKGROUND_2,
  MAIN_CONTENT,
  MAIN_CONTENT_TIME_TABLE,
} from 'src/assets/database/webData';
import {
  BOARD_SET_WIDTH_WEB,
  FIRST_BOARD_TEXT_IMAGE_WIDTH,
  SECOND_BOARD_IMAGE_WIDTH,
} from 'src/consts/user-web.const';
import { FlexBox, FlexBoxFullWidth } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

export default function WebMainPage() {
  return (
    <BoardMainPage>
      <FirstBoard url={MAIN_BACKGROUND_1}>
        <FirstBoardTextImage src={MAIN_BACKGROUND_1_TEXT_IMAGE} />
      </FirstBoard>
      <SecondBoard>
        <SecondBoardImage src={MAIN_CONTENT} />
      </SecondBoard>
      <ThirdBoard url={MAIN_BACKGROUND_2}>
        <ThirdBoardImage src={MAIN_CONTENT_TIME_TABLE} />
      </ThirdBoard>
    </BoardMainPage>
  );
}

const BoardMainPage = styled(FlexBox)`
  width: 100%;
  min-height: 100vh;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const FirstBoard = styled.div<{ url: string }>`
  position: relative;
  width: 100%;
  height: 800px;
  background-image: url(${({ url }) => url});
  background-position: center;
  background-size: cover;
`;

const FirstBoardTextImage = styled.img`
  width: ${FIRST_BOARD_TEXT_IMAGE_WIDTH}px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SecondBoard = styled.div`
  position: relative;
  width: 100%;
  height: 761px;
  background: linear-gradient(180deg, #fcfcfd 0%, #f1f1f7 100%);
`;

const SecondBoardImage = styled.img`
  width: ${SECOND_BOARD_IMAGE_WIDTH}px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ThirdBoard = styled(FlexBoxFullWidth)<{ url: string }>`
  position: relative;
  width: 100%;
  height: 1171px;
  background-image: url(${({ url }) => url});
  background-position: center;
  background-size: cover;
`;

const ThirdBoardImage = styled.img`
  width: ${BOARD_SET_WIDTH_WEB}px;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
`;
