export const IMG_BUCKET_PC = process.env.REACT_APP_S3_IMAGE_PC;

export const BOARD_SET_WIDTH_WEB = 1200;
export const EXCEPT_FOR_WEB_BOARD = 432;
export const LOGO_WIDTH = 210;
export const FIRST_BOARD_TEXT_IMAGE_WIDTH = 596;
export const SECOND_BOARD_IMAGE_WIDTH = 1140;
export const WEB_THEME_COLOR = '#3C3C61';

export const noticeInfoBlank = {
  id: 0,
  title: '',
  thumbnail: '',
  url: ' ',
  type: '',
  images: [{ imageId: 0, image: '' }],
  index: '',
  writer: '',
  content: '',
  createTime: '',
};

export const inquiryInfoBlank = {
  id: 0,
  title: '',
  userId: '',
  createdDate: '',
  content: '',
  answer: '',
  isAnswered: true,
};

export const inquiryBlank = {
  id: 0,
  title: '',
  isAnswered: false,
  isSecret: false,
  userId: '',
  createdDate: '',
};
