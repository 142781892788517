import {
  PROGRAM_CONTENT,
  PROGRAM_MAIN_BANNER,
  PROGRAM_MAIN_DAY1,
  PROGRAM_MAIN_DAY2,
  PROGRAM_MAIN_DAY3,
} from 'src/assets/database/webData';
import { BOARD_SET_WIDTH_WEB } from 'src/consts/user-web.const';
import { FlexBoxFullWidth } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

export default function WebProgramMain() {
  return (
    <ContainerProgramMain>
      <BannerMain url={PROGRAM_MAIN_BANNER}>대표 행사 프로그램</BannerMain>
      <ProgramBoard>
        <ProgramImage src={PROGRAM_MAIN_DAY1} />
        <ProgramImage src={PROGRAM_MAIN_DAY2} />
        <ProgramImage src={PROGRAM_MAIN_DAY3} />
        <ProgramImage src={PROGRAM_CONTENT} />
      </ProgramBoard>
    </ContainerProgramMain>
  );
}

const ContainerProgramMain = styled(FlexBoxFullWidth)`
  padding-bottom: 280px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const BannerMain = styled(FlexBoxFullWidth)<{ url: string }>`
  height: 240px;
  background-position: center;
  background-size: cover;
  background-image: url(${({ url }) => url});

  color: #fff;
  text-align: center;
  font-family: Cafe24Ohsquare;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 56px */

  justify-content: center;
  align-items: center;
`;

const ProgramBoard = styled(FlexBoxFullWidth)`
  padding-top: 80px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
`;

const ProgramImage = styled.img`
  width: ${BOARD_SET_WIDTH_WEB}px;
  &:last-of-type {
    margin-top: 96px;
  }
`;
