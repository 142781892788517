import { useState } from 'react';
import { FlexBox, FlexBoxFullWidth } from 'src/styles/OverviewStyle';
import styled from 'styled-components';
import { experienceProgramData } from 'src/assets/database/webData';
import { IExperienceProgramData } from 'src/interfaces/user-web.interface';
import WebProgramExperienceDetail from './WebProgramExperienceDetail';

export default function WebProgramExperience() {
  const [currentId, setCurrentId] = useState(1);
  const onClickButton = (event: React.MouseEvent<HTMLDivElement>) => {
    const id = Number(event.currentTarget.id);
    setCurrentId(id);
  };
  return (
    <ContainerProgram>
      <CategoryBar>
        <h1>체험 프로그램</h1>
        {/* <h2>레저 스포츠와 문화 예술의 융합</h2> */}
        <ButtonBox>
          {experienceProgramData.map((item: IExperienceProgramData) => (
            <ButtonElement
              key={item.id}
              selected={currentId === item.id}
              color={item.color}
            >
              <Circle id={item.id.toString()} onClick={onClickButton}>
                <Rect />
              </Circle>
              <ButtonText>{item.categoryName}</ButtonText>
            </ButtonElement>
          ))}
        </ButtonBox>
      </CategoryBar>
      <WebProgramExperienceDetail currentId={currentId} />
    </ContainerProgram>
  );
}

const ContainerProgram = styled(FlexBoxFullWidth)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 160px;
`;

const CategoryBar = styled(FlexBoxFullWidth)`
  width: 100%;
  flex-shrink: 0;
  padding: 80px 0 60px 0;

  background-color: #f5f5fb;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  > h1 {
    margin-bottom: 32px;

    color: var(--Font-02_black, #111);
    text-align: center;
    font-family: Cafe24Ohsquare;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    letter-spacing: -0.08px;
  }
  > h2 {
    margin-bottom: 16px;
    color: var(--Font-02_black, #111);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.045px;
  }
`;

const ButtonBox = styled(FlexBox)`
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
`;

const Circle = styled.div``;
const Rect = styled.div``;
const ButtonText = styled.h1``;
const ButtonElement = styled.div<{ selected: boolean; color: string }>`
  flex-shrink: 0;
  border-radius: 6px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.04px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  ${Circle} {
    position: relative;
    width: 88px;
    height: 88px;
    flex-shrink: 0;
    border-radius: 50%;
    cursor: pointer;

    background: ${props => (props.selected ? props.color : '#F0F0F1')};
  }
  ${Rect} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 64px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #fff;
  }
  ${ButtonText} {
    width: 88px;
    color: #505050;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
  }
`;
